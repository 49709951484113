import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private readonly loader = inject(LoaderService);

  // Holds a map of all requests that are currently loading
  private loaderMap = new Set<string>();

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isApiCall = request.url.includes('/api');
    const skip = !isApiCall || (request.headers.has('NoLoad') && request.headers.get('NoLoad') === 'true');
    if (skip) {
      // Remove the header to avoid passing it to the server
      const newHeaders = request.headers.delete('NoLoad');
      request = request.clone({ headers: newHeaders });
    } else {
      this.loaderMap.add(request.url);
      this.loader.startLoad(this.loaderMap.size);
    }

    const stopLoad = (event: HttpEvent<any> | HttpErrorResponse, request: HttpRequest<any>) => {
      if ((event.type == null || event.type === HttpEventType.Response) && request.url.includes('/api')) {
        this.loaderMap.delete(request.url);
        this.loader.stopLoad(this.loaderMap.size);

        if (this.loaderMap.size === 0) {
          this.loader.forceStopAll();
        }
      }
    };
    return next.handle(request).pipe(
      // On errors, stop the loader and rethrow the error
      catchError((error) => {
        stopLoad(error, request);
        throw error;
      }),
      // On response, stop the loader
      tap((event) => stopLoad(event, request)),
      // On completion, stop the loader (this takes care of request cancellations as well)
      finalize(() => stopLoad({ type: null } as unknown as HttpEvent<any>, request)),
    );
  }
}
