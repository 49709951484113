import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RequestCache } from '@logic-suite/shared/cache';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { BehaviorSubject, filter, map, pairwise, tap } from 'rxjs';
import { SiteOption } from './site-option.model';
import { SiteCode } from './site-code.enum';

@Injectable({ providedIn: 'root' })
export class SiteService {
  private readonly applicationStorageService = inject(ApplicationStorageService);
  private readonly cache = inject(RequestCache);

  availableSiteOptions: SiteOption[] = [
    {
      code: SiteCode.NO,
      name: 'Norge',
    },
    {
      code: SiteCode.SE,
      name: 'Sverige',
    },
    {
      code: SiteCode.FI,
      name: 'Suomi',
    },
  ];

  selectedSite$ = new BehaviorSubject(this.applicationStorageService.getItem('site', 'no'));
  selectedSite = toSignal(this.selectedSite$);
  siteChanged$ = this.selectedSite$.pipe(
    pairwise(),
    filter(([a, b]) => a !== b),
    tap(() => this.cache.invalidate()),
    map(([a, b]) => b),
  );

  constructor() {
    this.siteChanged$.subscribe((val) => {
      this.applicationStorageService.replaceItem('site', val);
    });
  }
}
